"use client";

import { useEffect } from 'react';
import * as CC from "vanilla-cookieconsent";
import { integratedCookieConfig } from './integratedCookieConfig';


export function CookieConsent() {
  useEffect(() => {
    CC.run(integratedCookieConfig)
  }, [])
  return null
}