"use client";

import { CheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { usePlausible } from 'next-plausible'

/* eslint-disable-next-line */
export interface AuthSuccessProps { }

export function AuthSuccess(props: AuthSuccessProps) {
  const plausible = usePlausible()
  const searchParams = useSearchParams()
  const [redirectLink, setRedirectLink] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const [serviceName, setServiceName] = useState('')
  const router = useRouter()
  
  useEffect(() => {
    const sredirectLink = searchParams.get('redirectLink');
    const sverificationId = searchParams.get('verificationId');
    const sserviceName = searchParams.get('serviceName');

    if (sredirectLink && sverificationId && sserviceName) {
      setRedirectLink(sredirectLink);
      setVerificationId(sverificationId);
      setServiceName(sserviceName);
      plausible("Authenticated", { props: { verificationId: sverificationId } })
    }
  }, [searchParams]);

  const authenticateWithAnotherInsitution = () => {
    router.push('/search?id=' + verificationId + '&serviceName=' + serviceName)
    plausible("Add another insitution", { props: { verificationId: verificationId } })
  }

  const redirectToService = () => {
    router.push(redirectLink)
    plausible("Redirect to service", { props: { verificationId: verificationId, redirectLink: redirectLink } })
    //TODO why this here?
    //window.location.href = redirectLink
  }

  return (
    <div>
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="text-xl leading-6 font-medium text-gray-900">
            Authentification was successful
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">

        <button
          type="button"
          className='className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm'
          onClick={() => redirectToService()}>
          <span>Return to {serviceName}</span>
        </button>

        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => authenticateWithAnotherInsitution()}>
          Add another institution
        </button>
      </div>
    </div>
  )
}
