"use client";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link"
import { useEffect, useState } from "react";
import {VerificationDemoButton} from "../../verification-demo-button/verification-demo-button";

const typeHeadings = ['students', 'researchers', 'educators']

export function Header() {
  const [typeHeading, setTypeHeading] = useState(typeHeadings[0])
  useEffect(() => {
    const interval = setInterval(() => {
      const pos = typeHeadings.indexOf(typeHeading)
      setTypeHeading(typeHeadings[pos + 1 > 2 ? 0 : pos + 1])
    }, 4000);
    return () => clearInterval(interval);
  }, [typeHeading]);
  return (
    <div className="bg-gray-50 overflow-hidden flex flex-col h-screen justify-center">
      <div className="hidden sm:block sm:absolute sm:inset-x-0 sm:h-full sm:w-full overflow-hidden pt-16" aria-hidden="true">
        <div className="relative h-full max-w-7xl mx-auto ">
          <svg className="absolute right-full transform translate-y-3/4 translate-x-1/4 lg:translate-x-1/2" width="250" height="404" fill="none" viewBox="0 0 250 404">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect>
              </pattern>
            </defs>
            <rect width="250" height="404" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"></rect>
          </svg>
          <svg className="absolute left-full transform -translate-y-1/ -translate-x-1/4 lg:-translate-x-24" width="500" height="500" fill="none" viewBox="0 0 500 500">
            <defs>
              <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect>
              </pattern>
            </defs>
            <rect width="500" height="500" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"></rect>
          </svg>
        </div>
      </div>
      <div className="relative -mt-28">
        <main className=" mx-auto max-w-7xl px-4">
          <div className="text-center">
            <div>
              <Link href="https://studid.io/docs/api" className="inline-flex space-x-4">
                <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                  What's new
                </span>
                <span className="inline-flex items-center text-sm font-medium text-indigo-600 space-x-1">
                  <span>Just shipped our first alpha release</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </Link>
            </div>
            <h1 className="text-4xl tracking-tight mt-6 font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Authenticate </span>
              <span className="block text-indigo-600 xl:inline">{typeHeading}</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Studid allows your educational users to authenticate natively with their institution's SSO, enhancing access security and convenience.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link passHref href="/docs" >
                  <button className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">Get started</button>
                </Link>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <VerificationDemoButton buttonText='Try it now' position="homepage-header" tailwindClasses="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10" />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}