/* import * as Sentry from '@sentry/react' */
import posthog from 'posthog-js'
import { CookieConsentConfig } from 'vanilla-cookieconsent'

export const integratedCookieConfig: CookieConsentConfig = {
  hideFromBots: true,
  cookie: {
    name: 'cc_cookie',
    domain: 'studid.io',
  },
  guiOptions: {
    consentModal: {
      layout: 'cloud inline',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false
    }
  },
  onConsent: ({cookie}) => {
    if (cookie.categories.includes('analytics')) {
      posthog.opt_in_capturing()
    }
  },
  categories: {
    necessary: {
      enabled: true,
      readOnly: true
    },
    analytics: {
      enabled: false,
      readOnly: false,
      autoClear: {
        cookies: [
          { name: /^ph_/ },
          { name: 'posthog_distinct_id' }
        ]
      },
      services: {
        posthog: {
          label: 'PostHog',
          onAccept: () => {
            posthog.opt_in_capturing()
          },
          onReject: () => {
            posthog.opt_out_capturing()
          }
        },
      }
    },
    /* monitoring: {
      enabled: false,
      readOnly: false,
      autoClear: {
        cookies: [
          { name: /^_ga/ },
          { name: '_gid' }
        ]
      },
      services: {
        sentry: {
          label: 'Sentry',
          onAccept: () => {
            Sentry.init({
              dsn: 'https://b79fb583cd734ad580dee56ed728b05e@o253575.ingest.sentry.io/6465262',
            })
          },
          onReject: () => {
            // Optionally, add logic for when monitoring is rejected
          }
        },
      }
    },*/
  }, 
  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'We use cookies',
          description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage Individual preferences',
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Your Privacy Choices',
              description: 'In this panel you can express some preferences related to the processing of your personal information. To deny your consent to the specific processing activities described below, switch the toggles to off or use the "Reject all" button and confirm you want to save your choices.',
            },
            {
              title: 'Strictly necessary',
              description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
              linkedCategory: 'necessary'
            },
            {
              title: 'Analytics',
              description: 'These cookies help us understand how visitors interact with the website, allowing us to improve our services.',
              linkedCategory: 'analytics'
            },
           /*  {
              title: 'Monitoring',
              description: 'These cookies help us monitor the performance and stability of our website.',
              linkedCategory: 'monitoring'
            } */
          ]
        }
      }
    }
  }
}