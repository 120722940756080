"use client";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ToastContainerWrapper() {
    return (
        <ToastContainer toastClassName={() => "pt-2 "
        } bodyClassName={() => ""} />
    );
}