"use client";
import { ErrorBoundary } from "react-error-boundary";
import { Fragment, useEffect, useState, useRef } from 'react'
import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { CloudIcon } from '@heroicons/react/24/outline'
import { IIdpSearch } from '@stud/shared/interfaces';
import axios from 'axios'
import _ from 'lodash'
import { debounceTime, distinctUntilChanged, fromEvent, map, switchMap } from 'rxjs'
import { RedirectButton } from '../redirect-button/redirect-button'
import { toast, ToastOptions, } from "react-toastify";
import { Alert } from '../alert/alert'
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InstitutionSearchProps {
}

export function InstitutionSearch(props: InstitutionSearchProps) {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [selectedEntity, setSelectedEntiy] = useState<IIdpSearch>({} as IIdpSearch)
  const [query, setQuery] = useState('')
  const [entityData, setEntityData] = useState<IIdpSearch[]>([])
  const searchInput = useRef<HTMLInputElement>(null)
  const [isSearching, setIsSearching] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const apiEndpoint = process.env['NEXT_PUBLIC_API_ENDPOINT'] ? process.env['NEXT_PUBLIC_API_ENDPOINT'] : 'https://api.studid.io'
  useEffect(() => {
    if (router && !searchParams.get('id')) router.push('/')
  }, [router, searchParams])
  useEffect(() => {
    const input = fromEvent<InputEvent>(searchInput.current as HTMLInputElement, 'input')
    input
      .pipe(
        map((event: InputEvent) => (event.target as HTMLInputElement).value),
        map((searchTerm) => _.trim(searchTerm)),
        debounceTime(700),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          if (searchTerm.length > 1) {
            setIsSearching(true)
            return axios.post(apiEndpoint + '/v1/search/entities', { q: searchTerm, country: '' }).then((res) => {
              return {
                status: res.status,
                details: res.status === 200 ? res.data.hits : [],
                result_hash: Date.now(),
              }
            }).catch(() => { return { status: false, details: '', result_hash: Date.now() } })
          } else {
            return Promise.resolve({ status: 200, details: [], result_hash: Date.now() })
          }
        }),
        //filter((res) => res.status !== false),
        distinctUntilChanged((a, b) => a.result_hash === b.result_hash),
      )
      .subscribe(async (res) => {
        //await new Promise((resolve) => setTimeout(resolve, 500))
        if (res.status === 200) {
          setIsTyping(false)
          setIsSearching(false)
          setEntityData(res.details)
        } else {
          console.log('failed request')
          setIsSearching(false)
          setIsTyping(false)
          setEntityData([])
          const options: ToastOptions = {
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
          };
          toast(<Alert title="Error while requesting search results" description="There seems to be a problem with our server. Please try again." />, options)
        }
      })
  }, [apiEndpoint])

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <div className="overflow-y-auto p-4 sm:p-6 md:p-20">
        <Combobox
          as="div"
          className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white  ring-1 ring-black ring-opacity-5 transition-all"
          value={selectedEntity}
          onChange={setSelectedEntiy}
          nullable
        >
          {({ activeOption }) => (
            <>
              <div className={classNames("relative", selectedEntity && selectedEntity.entityId && 'hidden sm:block')}>
                {isSearching ? (
                  <svg
                    className="animate-spin pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <Combobox.Input
                  ref={searchInput}
                  className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sm text-gray-800 placeholder-gray-400 focus:ring-0"
                  placeholder="Search..."
                  autoComplete="off"
                  maxLength={40}
                  displayValue={() => query}
                  onChange={(input) => {
                    setQuery(input.target.value)
                    //returns on mobile to search results and closes entity view
                    setSelectedEntiy({} as IIdpSearch)
                    input.target.value.length > 0 ? setIsTyping(true) : setIsTyping(false)
                  }}
                />
              </div>

              {(query === '' || entityData.length > 0) && (
                <div className="flex divide-x divide-gray-100">
                  <div
                    className={classNames(
                      'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto py-4  px-6',
                      activeOption && 'sm:h-96', selectedEntity && selectedEntity.entityId && 'hidden sm:block'
                    )}
                  >
                    {entityData.length === 0 && query === '' && (
                      <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500 ">
                        Examples: Uni Augsburg, UCLA
                      </h2>
                    )}
                    <Combobox.Options static hold className="-mx-2 text-sm text-gray-700">
                      {entityData.map((entity) => (
                        <Combobox.Option
                          key={entity.entityId}
                          value={entity}
                          className={({ active }) =>
                            classNames(
                              'flex cursor-default select-none items-center rounded-md p-2',
                              active && 'bg-gray-100 text-gray-900',
                            )
                          }
                        >
                          <span className="ml-3 flex-auto truncate ">
                            {entity.displayName}
                          </span>
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </div>
                  {selectedEntity && selectedEntity.displayName && (

                    <div className="flex w-full flex-col divide-y divide-gray-100 overflow-y-auto sm:h-96 sm:w-1/2 sm:flex-none">
                      <div className="flex-none p-6 text-center">
                        {selectedEntity.logo && (
                          <img
                            src={selectedEntity.logo.url}
                            alt=""
                            className="mx-auto max-h-14"
                          />
                        )}
                        <h2 className="mt-3 font-semibold text-gray-900">
                          {selectedEntity.displayName}
                        </h2>
                      </div>
                      <div className="flex flex-auto flex-col justify-between p-6">
                        <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                          <dt className="col-end-1 font-semibold text-gray-900">Country</dt>
                          <dd>{selectedEntity.country}</dd>
                          {selectedEntity.informationUrl && (
                            <Fragment>
                              <dt className="col-end-1 font-semibold text-gray-900">Website</dt>
                              <dd className="truncate">
                                <Link
                                  href={selectedEntity.informationUrl as string}
                                  target="_blank"
                                  className="text-indigo-600 underline"
                                  rel="noreferrer"
                                >
                                  {selectedEntity.informationUrl}
                                </Link>
                              </dd>
                            </Fragment>
                          )}
                        </dl>
                        <RedirectButton isRedirecting={isRedirecting} setIsRedirecting={setIsRedirecting} entityData={selectedEntity as IIdpSearch} />
                        <button onClick={() => setSelectedEntiy({} as IIdpSearch)} className="flex sm:hidden items-center justify-center mt-6 -mb-4 w-full py-2 px-4 text-sm font-medium text-indigo-600 hover:text-indigo-500">Return to search</button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {query !== '' && isTyping === false && entityData.length === 0 && (
                <div className="py-14 px-6 text-center text-sm sm:px-14">
                  <CloudIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                  <p className="mt-4 font-semibold text-gray-900">No instiutions found</p>
                  <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                </div>
              )}
            </>
          )}
        </Combobox>
      </div>
    </ErrorBoundary>
  )
}
