"use client";
import { IVerification } from "@stud/shared/interfaces"
import { getVerification } from "@stud/shared/utils"
import { useRouter, useSearchParams } from "next/navigation"
import { useState, useEffect } from "react"
import { ToastOptions, toast } from "react-toastify"
import { Alert } from "../alert/alert"


export interface VerificationDataProps {
  verificationId: string
}

//TODO add case where not jet verified. should show nothing or redirect to search?
export function VerificationData(props: VerificationDataProps) {
  const [verification, setVerification] = useState({} as IVerification)
  const searchParams = useSearchParams()
  const router = useRouter()
  useEffect(() => {
    const fetchVerification = async (verificationId: string) => {
      const verificationData = await getVerification(verificationId, 'is-this-secure?')
      if (verificationData.id) setVerification(verificationData)
      else {
        console.log('handle error')
        const options: ToastOptions = {
          autoClose: 8000,
          hideProgressBar: true,
          closeButton: false,
        }
        toast(<Alert title="Error fetching verification" description="The verification may allready be auto deleted. Create a new verification and try again." />, options)
      }
    }
    if (router && !searchParams.get('verificationId')) router.push('/404')
    else if (router && searchParams.get('verificationId'))
      fetchVerification(searchParams.get('verificationId') as string)
  }, [router, searchParams])
  return (
    <div className="bg-white shadow overflow-hidden rounded-lg">
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Verification</h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">Data for your verification</p>
    </div>
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">id</dt>
          <dd className="mt-1 text-sm text-gray-900 truncate">{verification.id}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">created</dt>
          <dd className="mt-1 text-sm text-gray-900 truncate">{String(verification.created)}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">sessions</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              {verification.sessions && verification.sessions.map(session => (
                <li key={session.entityId} className="pl-3 pr-4 py-3 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 text-sm">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">nameId</dt>
                    <dd className="mt-1 text-sm text-gray-900 truncate">{session.nameId}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">entityId</dt>
                    <dd className="mt-1 text-sm text-gray-900 truncate">{session.entityId}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">affiliations</dt>
                    <dd className="mt-1 text-sm text-gray-900 truncate">{session.affiliations.length > 0 ? session.affiliations.toString() : '[]'}</dd>
                  </div>
                </li>
              ))}
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
  )
}

