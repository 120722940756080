import { Dispatch, SetStateAction } from "react"
import axios from 'axios'
import { useSearchParams } from 'next/navigation'
import {Alert} from "../alert/alert"
import { toast, ToastOptions, } from "react-toastify";
import { usePlausible } from "next-plausible"
import { IIdpSearch } from '@stud/shared/interfaces';

export interface RedirectButtonProps {
  isRedirecting: boolean
  setIsRedirecting: Dispatch<SetStateAction<boolean>>
  entityData: IIdpSearch
}


export function RedirectButton(props: RedirectButtonProps) {
  const sarchParams = useSearchParams()
  const apiEndpoint = process.env['NEXT_PUBLIC_API_ENDPOINT'] ? process.env['NEXT_PUBLIC_API_ENDPOINT'] : 'https://api.studid.io'
  const plausible = usePlausible()

  const redirectToIDP = async (entityId: string, verificationId: string) => {
    await new Promise((resolve) => setTimeout(resolve, 500))
    const res = await axios
      .post(apiEndpoint + '/v0.1/verification/create-saml-request', { entityId: entityId, verificationId: verificationId })
      .then((res) => {
        return {
          status: res.status === 201,
          details: res.status === 201 ? res.data : '',
        }
      }).catch(() => { return { status: false, details: '' } })
    if (res.status) {
      window.location.href = res.details.link
      plausible("Authenticate", { props: { verificationId: verificationId, entityId: entityId } })
      props.setIsRedirecting(false)
    }
    else {
      const options: ToastOptions = {
        autoClose: 8000,
        hideProgressBar: true,
        closeButton: false,
      }
      toast(<Alert title="Error generateing authentification link" description="An error occurred processing your request. Please check your input for any possible errors and try again. If the problem persists, contact support for assistance or try after some time." />, options)
      props.setIsRedirecting(false)
    }
  }
  return (
    <span>
      {props.isRedirecting ? (
        <button type="button" className="flex items-center justify-center mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm transition ease-in-out duration-150 cursor-not-allowed hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" disabled>
          <svg
            className="animate-spin pointer-events-none -ml-1   h-5 w-5 mr-3 text-white"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" fill="#8203" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Redirecting...
        </button>
      ) : (
        <button
          type="button"
          className="mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={async () => {
            props.setIsRedirecting(true)
            await redirectToIDP(props.entityData.entityId, sarchParams.get("id") as string)
          }
          }
        >
          Authenticate
        </button>
      )}
    </span>
  )
}