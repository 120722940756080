'use client'

import { useEffect } from 'react'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

const POSTHOG_KEY = process.env['NEXT_PUBLIC_POSTHOG_KEY']
const POSTHOG_HOST = process.env['NEXT_PUBLIC_POSTHOG_HOST']

if (typeof window !== 'undefined') {
  posthog.init(POSTHOG_KEY!, {
    api_host: POSTHOG_HOST,
    opt_out_capturing_by_default: true
  })
}

export function PostHogSetup({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // You can add any additional setup logic here
  }, [])

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}