"use client";

import { SpeakerWaveIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import { useEffect, useState } from 'react'

export interface NewsBannerProps {
  mobileText: string
  desktopText: string
  buttonText: string
  buttonLink: string
}

const saveNewsItemToLocalStorage = (news: NewsBannerProps) => {
  localStorage.setItem('news_banner', JSON.stringify(news))
}

export function NewsBanner(props: NewsBannerProps) {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    const closedNewsBannerItem = localStorage.getItem('news_banner')
    closedNewsBannerItem && closedNewsBannerItem === JSON.stringify(props) ? setIsOpen(false) : setIsOpen(true)
  }, [props])

  if (isOpen)
    return (
      <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-indigo-800">
                  <SpeakerWaveIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">{props.mobileText}</span>
                  <span className="hidden md:inline">{props.desktopText}</span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <Link passHref href={props.buttonLink} >
                  <span className='flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-indigo-600 bg-white hover:bg-indigo-50'>
                    <button className='text-sm font-medium'>
                      {props.buttonText}
                    </button>
                  </span>
                </Link>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={() => { setIsOpen(false); saveNewsItemToLocalStorage(props) }}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

