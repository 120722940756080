import axios from 'axios'

export interface IVerificationResponse {
  status: number
  id?: string
  link?: string
}

export async function createVerification(
  secretToken: string,
  redirectUrl: string,
  serviceName: string,
): Promise<IVerificationResponse> {
  const apiDomain = process.env['NEXT_PUBLIC_API_ENDPOINT']
    ? process.env['NEXT_PUBLIC_API_ENDPOINT']
    : 'https://api.studid.io'
  const apiVersion = 'v0.1'
  const createVerificationEndpoint = apiDomain + '/' + apiVersion + '/verification/create'
  const axiosResp = await axios.post(createVerificationEndpoint, {
    secretToken: secretToken,
    redirectUrl: redirectUrl,
    serviceName: serviceName,
  })
  const vR = {} as IVerificationResponse
  vR.status = axiosResp.status
  if (vR.status === 201) {
    vR.id = axiosResp.data.id
    vR.link = axiosResp.data.link
  }
  return vR
}

export default createVerification
