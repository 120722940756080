import { IBlogPost } from '@stud/shared/interfaces'
import fetchContentfulGraphQL from './fetchContentfulGraphQL'

export async function getBlogPostBySlug(slug: string, locale?: string): Promise<IBlogPost> {
  locale = !locale ? 'en' : locale
  const document = await fetchContentfulGraphQL(
    `query {
                blogPostCollection(where: { slug: "${slug}" }) {
                        items {
                                sys {
                                        id
                                }
                                title (locale: "${locale}")
                                post (locale: "${locale}") {
                                        json
                                }
                                category
                                slug
                                description
                                publishedAt
                        }
                }
        }`,
  )
  const blogPost = document.data.blogPostCollection.items[0]
  return {
    id: blogPost.sys.id,
    title: blogPost.title,
    post: blogPost.post,
    category: blogPost.category,
    slug: blogPost.slug,
    description: blogPost.description,
    publishedAt: new Date(blogPost.publishedAt),
  }
}
