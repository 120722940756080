"use client"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import { IFederation } from "@stud/saml-metadata"
import { createEntityUrl } from "@stud/shared/utils"
import Link from "next/link"
import { useRouter } from "next/navigation"

/* eslint-disable-next-line */
export interface EntitiesTableProps {
  federation: IFederation
  entity: any
}

export function EntitiesTable(props: EntitiesTableProps) {
  const router = useRouter()
  const entityLink = createEntityUrl(props.federation.id, props.entity.displayName, props.entity.entityID)
  return (
    <tr key={props.entity.id} onClick={() => router.push(entityLink)} className="hover:cursor-pointer hover:bg-gray-100">
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
        <Link href={entityLink} className="inline-block w-full h-full">{props.entity.displayName}</Link>
        <dl className="font-normal lg:hidden">
          <dt className="sr-only sm:hidden">Country</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden"><Link href={entityLink} className="inline-block w-full h-full">{props.entity.countries}</Link></dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"><Link href={entityLink} className="inline-block w-full h-full">{props.entity.description}</Link></td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"><Link href={entityLink} className="inline-block w-full h-full">{props.entity.country}</Link></td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <Link href={entityLink} className="inline-block w-full h-full">
          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        </Link>
      </td>
    </tr>
  )
}

export default EntitiesTable
