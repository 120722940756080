import { IVerification } from '@stud/shared/interfaces'
import axios from 'axios'

export async function getVerification(verificationId: string, secretToken: string): Promise<IVerification> {
  const apiDomain = process.env['NEXT_PUBLIC_API_ENDPOINT']
    ? process.env['NEXT_PUBLIC_API_ENDPOINT']
    : 'https://api.studid.io'
  const apiVersion = 'v0.1'
  const createVerificationEndpoint = apiDomain + '/' + apiVersion + '/verification'
  let verification = {} as IVerification
  try {
    const axiosResp = await axios.get(createVerificationEndpoint, {
      params: { secretToken: secretToken, id: verificationId },
    })
    verification = axiosResp.data
    return verification
  } catch (e) {
    return verification
  }
}

export default getVerification
