import { IBlogPost } from '@stud/shared/interfaces'
import fetchContentfulGraphQL from './fetchContentfulGraphQL'

export async function getBlogPost(id: string, locale?: string): Promise<IBlogPost> {
  locale = !locale ? 'en' : locale
  const document = await fetchContentfulGraphQL(
    `query {
        blogPost(id: "${id}") {
            sys {
                id
            }
            title (locale: "${locale}")
            post (locale: "${locale}") {
                json
            }
            category
            slug
            description
            publishedAt
        }
    }`,
  )
  return {
    id: document.data.blogPost.sys.id,
    title: document.data.blogPost.title,
    post: document.data.blogPost.post,
    category: document.data.blogPost.category,
    slug: document.data.blogPost.slug,
    description: document.data.blogPost.description,
    publishedAt: new Date(document.data.blogPost.publishedAt),
  }
}
