import { IFederation } from '@stud/saml-metadata'
import { IIdpSearch } from '@stud/shared/interfaces'
import { createClient } from '@supabase/supabase-js'

export class SupabaseService {
  private supabase = createClient(process.env['SUPABASE_URL'] as string, process.env['SUPABASE_SECRET_KEY'] as string)

  async getEntityCount() {
    const count = (await this.supabase.from('entities').select('*', { count: 'exact', head: true })).count
    return count
  }

  async getFederations(): Promise<IFederation[]> {
    return (await this.supabase.from('federations').select('*').eq('live', true)).data as IFederation[]
  }

  async getFederation(id: string): Promise<IFederation> {
    return (await this.supabase.from('federations').select('*').eq('id', id)).data[0]
  }

  async getEntities(): Promise<any> {
    return (await this.supabase.from('entities').select('*')).data
  }

  async getEntitiesByFederation(federationId: string): Promise<any> {
    return (await this.supabase.from('entities').select('*').eq('federation', federationId)).data
  }

  async getEntity(entityId: string): Promise<any> {
    return (await this.supabase.from('entities').select('*').eq('entityID', entityId)).data[0]
  }
}
