"use client";

import { createVerification } from "@stud/shared/utils"
import { usePlausible } from "next-plausible"
import { useRouter } from "next/navigation"
import { useEffect, useState } from "react"
import { toast, ToastOptions } from "react-toastify"
import { Alert } from "../alert/alert"

/* eslint-disable-next-line */
export interface VerificationDemoButtonProps {
  tailwindClasses: string
  position?: string
  buttonText: string
}

export function VerificationDemoButton(props: VerificationDemoButtonProps) {
  const router = useRouter()
  const [disableButton, setDisableButton] = useState(false)
  const [buttonText, setButtonText] = useState(props.buttonText)
  const [createVerificationError, setCreateVerificationError] = useState(false)
  const plausible = usePlausible()
  useEffect(() => {
    if (createVerificationError) {
      const options: ToastOptions = {
        autoClose: 4000,
        hideProgressBar: true,
        closeButton: false,
      }
      toast(<Alert title="Error starting live demo" description="There seems to be a problem with our server. Please try again." />, options)
    }
  }, [createVerificationError])
  const redirectToDemoSearch = async () => {
    setCreateVerificationError(false)
    setDisableButton(true)
    const redirectDomain = process.env['NEXT_PUBLIC_DOMAIN'] ? process.env['NEXT_PUBLIC_DOMAIN'] : 'https://studid.io'
    try {
      setButtonText('Starting...')
      const verification = await createVerification('is-this-secure?', redirectDomain + '/view-verification-data', 'LIVE DEMO')
      if (verification.status === 201 && verification.link) {
        const urlWithoutDomain = verification.link.split("/")[3]
        plausible("Demo button", { props: { verificationId: verification.id, position: props.position } })
        router.push('/' + urlWithoutDomain)
        setDisableButton(false)
        setButtonText(props.buttonText)
      } else {
        setCreateVerificationError(true)
        setButtonText(props.buttonText)
        setDisableButton(false)
      }
    } catch (e) {
      setCreateVerificationError(true)
      setButtonText(props.buttonText)
      setDisableButton(false)
    }
  }
  return (
    <div>
      <button className={props.tailwindClasses} onClick={redirectToDemoSearch} disabled={disableButton}>
        {disableButton && (
          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        )}
        {buttonText}
      </button>
    </div>

  )
}