"use client";

import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { BookOpenIcon, ListBulletIcon, BuildingLibraryIcon} from '@heroicons/react/24/outline'
import Link from 'next/link'
import { useEffect } from 'react'
import { usePlausible } from 'next-plausible'

const links = [
  { title: 'Federations', description: 'See the entities available for authentication', icon: BuildingLibraryIcon, link: '/federations' },
  { title: 'Documentation', description: 'Learn how to integrate our tools with your app', icon: BookOpenIcon, link: 'https://studid.io/docs' },
  { title: 'API Reference', description: 'A complete API reference for our libraries', icon: ListBulletIcon, link: 'https://studid.io/docs/api' },
]

/* eslint-disable-next-line */
export interface NotFoundProps { }

export function NotFound(props: NotFoundProps) {
  const plausible = usePlausible()
  useEffect(() => {
    const url = window.location.href
    plausible("404", { props: { path: url } })
  }, [plausible])

  return (
      <div className="max-w-xl mx-auto py-16 sm:py-24 flex-grow">
        <div className="text-center">
          <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            This page does not exist.
          </h1>
          <p className="mt-2 text-lg text-gray-500">The page you are looking for could not be found.</p>
        </div>
        <div className="mt-12">
          <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Popular pages</h2>
          {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
          <ul role="list" className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
            {links.map((link, linkIdx) => (
              <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                    <link.icon className="h-6 w-6 text-indigo-700" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                    <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                        <Link href={link.link} className="focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true" />
                          {link.title}
                      </Link>
                    </span>
                  </h3>
                  <p className="text-base text-gray-500">{link.description}</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-8">
            <span className="text-base font-medium text-indigo-600 hover:text-indigo-500">
              <Link href="/">
                Or go back home
              </Link>
              <span aria-hidden="true"> &rarr;</span>
            </span>
          </div>
        </div>
      </div>
  )
}
