import { IBlogPost } from '@stud/shared/interfaces'
import fetchContentfulGraphQL from './fetchContentfulGraphQL'

export async function getBlogPosts(locale?: string): Promise<[IBlogPost]> {
  locale = !locale ? 'en' : locale
  const document = await fetchContentfulGraphQL(
    `query {
        blogPostCollection {
          items {
            sys {
                id
            }
            title (locale: "${locale}")
            post (locale: "${locale}") {
                json
            }
            category
            slug
            description
            publishedAt
          }
        }
      }`,
  )
  return document.data.blogPostCollection.items.map((item) => ({
    id: item.sys.id,
    title: item.title,
    post: item.post,
    category: item.category,
    slug: item.slug,
    description: item.description,
    publishedAt: new Date(item.publishedAt),
  }))
}
