import { ILegalDocument } from '@stud/shared/interfaces'
import fetchContentfulGraphQL from './fetchContentfulGraphQL'

export async function getLegalDocument(id: string, locale: string): Promise<ILegalDocument> {
  const document = await fetchContentfulGraphQL(
    `query {
      legalDocument(id: "${id}") {
        sys {
          id
        }
        title(locale: "${locale}")
        document(locale: "${locale}") {
          json
        }
        publishedAt
      }
    }`,
  )
  return {
    id: document.data.legalDocument.sys.id,
    title: document.data.legalDocument.title,
    document: document.data.legalDocument.document,
    publishedAt: new Date(document.data.legalDocument.publishedAt),
  }
}
