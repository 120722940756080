export default async function fetchContentfulGraphQL(query: string) {
  try {
    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env['CONTENTFUL_SPACE_ID']}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Switch the Bearer token depending on whether the fetch is supposed to retrieve live
          // Contentful content or draft content
          Authorization: `Bearer ${process.env['CONTENTFUL_ACCESS_TOKEN']}`,
        },
        body: JSON.stringify({ query }),
        // Associate all fetches for articles with an "articles" cache tag so content can
        // be revalidated or updated from Contentful on publish
        //next: { tags: ["articles"] },
      },
    )
    // network error in the 4xx–5xx range
    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`)
    }
    // use response here if we didn't throw above
    return response.json()
  } catch (error) {
    console.error(error)
  }
}
