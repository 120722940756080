import { Info } from '@stud/saml-metadata'
import * as _ from 'lodash'

export function getUiInfoInUserLanguage(entityInfos: Info[]): Info {
  let info: Info
  const userLanguage = navigator.language.split('-')[0]
  info = _.find(entityInfos, { lang: userLanguage }) as Info
  if (!info) info =_.find(entityInfos, { lang: 'en' }) as Info
  if (!info) info = entityInfos[0]
  return info
}

export default getUiInfoInUserLanguage